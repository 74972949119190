.question {
  background-color: map-get($colors, 'main');

  &__content {
    padding-top: 49px;
    padding-bottom: 71px;
  }

  &__title {
    margin-bottom: 6px;

    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;

    color: #fff;
  }

  &__text {
    margin-bottom: 40px;

    line-height: 1.61;
    text-align: center;

    color: #fff;
  }
}

@media (min-width: 768px) {
  .question {
    &__content {
      padding-top: 62px;
      padding-bottom: 122px;
    }

    &__title {
      font-size: 35px;
    }

    &__text {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 36px;
    }
  }
}

@media (min-width: 1200px) {
  .question {
    &__content {
      padding-top: 81px;
      padding-bottom: 117px;
    }

    &__title {
      text-align: left;
    }

    &__text {
      max-width: none;
      margin-bottom: 16px;

      text-align: left;
    }
  }
}
