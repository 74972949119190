.steps {
  margin-bottom: 7px;

  font-size: 12px;
  line-height: 2.09;

  color: rgba(17, 164, 64, 0.46);
}

.slider {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .module {
    width: 320px;
  }
}
