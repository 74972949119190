.contacts {
  &__content {
    padding-top: 87px;
    padding-bottom: 76px;
  }

  &__title {
    margin-bottom: 32px;

    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'second');
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 33px;
    margin-bottom: 32px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6px;

    font-weight: 500;
    line-height: 1.76;
    text-align: center;

    color: map-get($colors, 'second');

    &::before {
      position: absolute;
      top: 0;
      left: 50%;

      width: 30px;
      height: 3px;

      background-color: map-get($colors, 'main');
      transform: translateX(-50%);

      content: '';
    }

    a {
      transition: 0.2s;

      &:active {
        color: map-get($colors, 'main');
      }
    }
  }

  &__networks {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    padding-top: 10px;

    a {
      display: flex;
      width: 24px;
    }
  }

  &__requisites {
    display: block;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 48px;
    padding-right: 48px;

    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    border-radius: 3px;
    color: #fff;
    background-color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      background-color: map-get($colors, 'main-light');
    }
  }
}

@media (min-width: 768px) {
  .contacts {
    &__content {
      padding-top: 75px;
      padding-bottom: 37px;
    }

    &__title {
      margin-bottom: 30px;

      font-size: 38px;
    }

    &__list {
      flex-direction: row;
      justify-content: space-between;
    }

    &__item {
      flex-shrink: 0;
      align-items: flex-start;
      width: 243px;
      padding-top: 3px;

      text-align: left;

      &::before {
        left: 0;

        transform: none;
      }

      &:first-child {
        width: 150px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    &__content {
      padding-top: 143px;
      padding-bottom: 49px;
    }

    &__title {
      text-align: left;
    }

    &__item {
      width: 300px;
    }
  }
}

@media (hover: hover) {
  .contacts {
    &__item {
      a {
        &:hover {
          color: map-get($colors, 'main');
        }
      }
    }

    &__requisites {
      &:hover {
        background-color: map-get($colors, 'main-light');
      }
    }
  }
}
