.name {
  margin-bottom: 20px;
}

.phone {
  margin-bottom: 20px;
}

.policy {
  margin-bottom: 29px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;

  font-weight: 500;
  text-transform: uppercase;

  border-radius: 3px;
  color: #3a99a3;
  background-color: #fff;

  transition: 0.2s;

  &:active {
    color: #fff;
    background-color: #143437;
  }
}

@media (min-width: 768px) {
  .module {
    position: relative;

    display: flex;
  }

  .name {
    width: 33.33333%;
    margin-right: 15px;
    margin-bottom: 0;
  }

  .phone {
    width: 33.33333%;
    margin-right: 15px;
    margin-bottom: 0;
  }

  .button {
    width: calc(33.33333% - 30px);
    margin: 0;
  }

  .policy {
    position: absolute;
    top: calc(100% + 13px);
    left: calc(66.66666% + 30px);

    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .name {
    width: 320px;
  }

  .phone {
    width: 320px;
  }

  .button {
    width: 320px;
  }

  .policy {
    max-width: 250px;

    left: 670px;
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      color: #fff;
      background-color: #143437;
    }
  }
}
