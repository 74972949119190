.footer {
  &__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 38px;
    padding-bottom: 62px;
  }

  &__logo {
    width: 116px;
    margin-bottom: 28px;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 17px;
    margin-bottom: 51px;

    a {
      line-height: 2.44;

      color: map-get($colors, 'second');

      transition: 0.2s;

      &:active {
        color: map-get($colors, 'main');
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__phones {
    margin-bottom: 17px;
  }

  &__phone {
    display: flex;
    align-items: center;

    transition: 0.2s;

    img {
      width: 16px;
      margin-right: 5px;
    }

    span {
      font-weight: 500;
      line-height: 2.44;

      transition: 0.2s;

      color: map-get($colors, 'second');
    }

    &:active {
      span {
        color: map-get($colors, 'main');
      }
    }
  }

  &__callback {
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;

    text-align: center;

    border: 1px solid map-get($colors, 'main');
    border-radius: 3px;
    color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__bottom {
    background-color: map-get($colors, 'second');
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 47px;
    padding-bottom: 43px;
  }

  &__copyright {
    margin-bottom: 15px;

    font-weight: 500;
    line-height: 1.74;

    color: #fff;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    button {
      line-height: 1.74;

      color: rgba(255, 255, 255, 0.8);

      transition: 0.2s;

      &:active {
        color: map-get($colors, 'main-light');
      }
    }
  }
}

@media (min-width: 768px) {
  .footer {
    &__top-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 31px;
      padding-bottom: 55px;
    }

    &__logo {
      margin-bottom: 0;
    }

    &__nav {
      flex-wrap: wrap;
      align-content: space-between;
      row-gap: 7px;
      width: 210px;
      height: 86px;
      margin-bottom: 0;
    }

    &__phones {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 8px;
    }

    &__bottom-content {
      padding-top: 19px;
      padding-bottom: 39px;
    }

    &__buttons {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;

      button {
        width: 50%;
        text-align: center;
      }
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__top-content {
      padding-top: 51px;
    }

    &__nav {
      flex-direction: row;
      column-gap: 77px;
      align-self: center;
      width: auto;
      height: auto;
    }

    &__buttons {
      justify-content: space-between;

      button {
        width: auto;
      }
    }
  }
}

@media (hover: hover) {
  .footer {
    &__nav {
      a {
        &:hover {
          color: map-get($colors, 'main');
        }
      }
    }

    &__phone {
      &:hover {
        span {
          color: map-get($colors, 'main');
        }
      }
    }

    &__callback {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }

    &__buttons {
      button {
        &:hover {
          color: map-get($colors, 'main-light');
        }
      }
    }
  }
}
