.module {
  position: relative;

  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 20px;

  border-radius: 3px;
  background-color: #fff;

  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"%3E%3Cpolyline points="1 3, 6 8, 11 3" stroke="%23999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 17px center;
  background-size: 10px;
  cursor: pointer;

  .name {
    color: rgba(0, 0, 0, 0.4);
  }

  .select {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 20px;
  }
}
