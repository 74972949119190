.module {
  position: relative;

  width: 670px;
  max-width: 100%;
  padding-top: 59px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 58px;
  overflow: hidden;

  background-color: #f9f9f9;
}

.bg {
  position: absolute;
  top: -50px;
  left: -50px;

  width: 217px;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 37px;

  font-size: 23px;
  font-weight: 800;
  line-height: 1.3069;
  text-align: center;
  text-transform: uppercase;

  color: #3a99a3;
}

.name {
  margin-bottom: 20px;
}

.phone {
  margin-bottom: 13px;
}

.policy {
  margin-bottom: 37px;
}

.button {
  align-self: center;
  width: 100%;
  height: 60px;

  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  border-radius: 3px;
  color: #fff;
  background-color: #3a99a3;

  transition: 0.2s;

  &:active {
    background-color: lighten(#3a99a3, 10%);
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 30px;
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      background-color: lighten(#3a99a3, 10%);
    }
  }
}
