.rooms {
  $p: &;

  &__content {
    padding-top: 80px;
    padding-bottom: 61px;
  }

  &__bg {
    display: none;
  }

  &__title {
    margin-bottom: 22px;

    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;

    strong {
      color: map-get($colors, 'main');
    }

    span {
      color: map-get($colors, 'second');
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-bottom: 22px;

    &--all {
      margin-bottom: 0;

      #{$p}__item {
        &:nth-child(n + 4) {
          display: block;
        }
      }

      & + #{$p}__more {
        display: none;
      }
    }
  }

  &__item {
    &:nth-child(n + 4) {
      display: none;
    }
  }

  &__slider {
    position: relative;

    overflow: hidden;

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 97%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -4px;

    width: 42px;
    height: 42px;

    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 33px;
      height: 33px;

      border: 1px solid map-get($colors, 'main');
      background-color: rgba(255, 255, 255, 0.57);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 19px;

      width: 8px;
      height: 8px;

      border-left: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -4px;

    width: 42px;
    height: 42px;

    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 33px;
      height: 33px;

      border: 1px solid map-get($colors, 'main');
      background-color: rgba(255, 255, 255, 0.57);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 19px;

      width: 8px;
      height: 8px;

      border-right: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(-45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__body {
    padding-top: 22px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 19px;

    border: 1px solid #ececec;
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__top {
    margin-bottom: 17px;
  }

  &__name {
    margin-bottom: 5px;

    font-size: 20px;
    font-weight: 700;
    line-height: 1.39;
    text-transform: uppercase;

    color: map-get($colors, 'second');
  }

  &__price {
    strong {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.36;

      color: map-get($colors, 'main');
    }

    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.39;

      color: map-get($colors, 'second');
    }
  }

  &__values {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    margin-bottom: 22px;
  }

  &__value {
    display: flex;

    line-height: 1.14;

    color: map-get($colors, 'second');

    strong {
      margin-right: 9px;

      font-weight: 600;
    }
  }

  &__properties {
    display: flex;
    flex-wrap: wrap;
    row-gap: 7px;
    column-gap: 20px;
    margin-bottom: 36px;

    span {
      display: inline-flex;
      align-items: center;

      font-size: 14px;
      font-weight: 500;
      line-height: 1.74;

      color: map-get($colors, 'second');

      &::before {
        width: 5px;
        height: 5px;
        margin-right: 5px;

        border-radius: 50%;
        background-color: map-get($colors, 'main');

        content: '';
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__calculation {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;

    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    border: 1px solid map-get($colors, 'main');
    border-radius: 3px;
    color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__booking {
    width: 100%;
    height: 60px;

    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    border-radius: 3px;
    color: #fff;
    background-color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      background-color: map-get($colors, 'main-light');
    }
  }

  &__more {
    display: flex;
    margin-left: auto;
    margin-right: auto;

    font-weight: 500;
    line-height: 1.7369;
    text-transform: uppercase;

    color: map-get($colors, 'main');

    &:active {
      color: map-get($colors, 'main-light');
    }
  }
}

@media (min-width: 768px) {
  .rooms {
    &__content {
      position: relative;

      padding-top: 61px;
      padding-bottom: 77px;
    }

    &__bg {
      position: absolute;
      right: -60px;
      bottom: 80px;

      display: block;
      width: 159px;
    }

    &__title {
      margin-bottom: 31px;

      font-size: 38px;
    }

    &__list {
      margin-bottom: 30px;

      &--all {
        margin-bottom: 0;
      }
    }

    &__item {
      position: relative;
    }

    &__slide {
      padding-bottom: 48%;
    }

    &__prev {
      left: 0;

      width: 46px;
      height: 46px;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__next {
      right: 0;

      width: 46px;
      height: 46px;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__body {
      padding-top: 28px;
      padding-left: 35px;
      padding-right: 30px;
      padding-bottom: 32px;
    }

    &__top {
      display: flex;
      margin-bottom: 27px;
    }

    &__name {
      flex: 0 0 50%;
      margin-bottom: 0;

      font-size: 23px;
    }

    &__price {
      flex: 0 0 50%;

      text-align: right;
    }

    &__values {
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 13px;
      max-width: 506px;

      margin-bottom: 27px;
    }

    &__value {
      width: 253px;
    }

    &__buttons {
      flex-direction: row;
    }

    &__calculation {
      width: calc(50% - 12px);
      margin-right: 12px;
      margin-bottom: 0;
    }

    &__booking {
      width: calc(50% - 12px);
      margin-left: 12px;
    }
  }
}

@media (min-width: 1200px) {
  .rooms {
    $p: &;

    &__content {
      padding-top: 112px;
      padding-bottom: 127px;
    }

    &__bg {
      bottom: -110px;
      right: -180px;

      width: 227px;
    }

    &__title {
      margin-bottom: 51px;

      text-align: left;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -15px;
      margin-bottom: 34px;

      &--all {
        margin-bottom: 0;

        #{$p}__item {
          &:nth-child(n + 7) {
            display: block;
          }
        }
      }
    }

    &__item {
      width: calc(50% - 30px);
      margin: 0 15px;

      &:nth-child(n + 4) {
        display: block;
      }

      &:nth-child(n + 7) {
        display: none;
      }
    }

    &__slide {
      padding-bottom: 55%;
    }

    &__body {
      padding-top: 35px;
      padding-left: 24px;
      padding-right: 22px;
      padding-bottom: 29px;
    }

    &__top {
      justify-content: space-between;
      margin-bottom: 25px;
    }

    &__name {
      flex-basis: 260px;
      min-height: 64px;
    }

    &__price {
      flex-basis: auto;
    }

    &__value {
      width: 50%;
    }

    &__properties {
      margin-bottom: 18px;
    }

    &__calculation {
      flex-grow: 1;
      width: auto;
      margin-right: 19px;
    }

    &__booking {
      width: 209px;
      margin-left: 0;
    }
  }
}

@media (hover: hover) {
  .rooms {
    &__prev {
      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__calculation {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }

    &__booking {
      &:hover {
        background-color: map-get($colors, 'main-light');
      }
    }

    &__more {
      &:hover {
        color: map-get($colors, 'main-light');
      }
    }
  }
}
