.module {
  display: flex;
  justify-content: center;
}

.prev {
  position: relative;

  height: 60px;
  width: 60px;
  margin-right: 20px;

  border: 1px solid #3a99a3;
  border-radius: 3px;

  transition: 0.2s;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 20px;
    height: 2px;

    background-color: #3a99a3;
    transform: translate(-50%, -50%);

    transition: 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 23px;
    left: 20px;

    width: 12px;
    height: 12px;

    border-left: 2px solid #3a99a3;
    border-bottom: 2px solid #3a99a3;
    transform: rotate(45deg);

    transition: 0.2s;

    content: '';
  }

  &:active {
    background-color: #3a99a3;

    &::before {
      background-color: #fff;
    }

    &::after {
      border-color: #fff;
    }
  }
}

.next {
  height: 60px;
  flex-grow: 1;

  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  border-radius: 3px;
  color: #fff;
  background-color: #3a99a3;

  transition: 0.2s;

  &:active {
    background-color: lighten(#3a99a3, 10%);
  }
}

.confirm {
  height: 60px;
  width: 100%;

  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  border-radius: 3px;
  color: #fff;
  background-color: #3a99a3;

  transition: 0.2s;

  &:active {
    background-color: lighten(#3a99a3, 10%);
  }
}

@media (hover: hover) {
  .prev {
    &:hover {
      background-color: #3a99a3;

      &::before {
        background-color: #fff;
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  .next {
    &:hover {
      background-color: lighten(#3a99a3, 10%);
    }
  }

  .confirm {
    &:hover {
      background-color: lighten(#3a99a3, 10%);
    }
  }
}
