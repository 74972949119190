.module {
  width: 100%;
  height: 180px;
  padding: 16px 20px;

  border-radius: 3px;
  background-color: #fff;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}
