.module {
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  padding-right: 35px;

  border-radius: 3px;
  background-color: #fff;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"%3E%3Cpolyline points="1 3, 6 8, 11 3" stroke="%23999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 17px center;
  background-size: 10px;
  cursor: pointer;

  strong {
    margin-right: auto;

    color: rgba(0, 0, 0, 0.4);
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  padding-top: 27px;
  padding-left: 20px;

  padding-bottom: 21px;

  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 34px;
  max-height: 200px;
  padding-right: 20px;
  overflow-y: scroll;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.select {
  width: 127px;
  height: 30px;

  border-bottom: 1px solid #a6a6a6;
  border-radius: 0;

  background-position: right 0 center;
}

.button {
  margin-right: 20px;
  height: 60px;

  border-radius: 3px;
  color: #fff;
  background-color: #3a99a3;

  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
