.calculation {
  background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 67px;
    padding-bottom: 67px;
  }

  &__textual {
    margin-bottom: 18px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;

    strong {
      color: map-get($colors, 'main');
    }

    span {
      color: map-get($colors, 'second');
    }
  }

  &__text {
    line-height: 1.58;
    text-align: center;

    color: map-get($colors, 'second');
  }

  &__image {
    position: relative;

    margin-bottom: 28px;
    padding-bottom: 93%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: 768px) {
  .calculation {
    &__content {
      position: relative;

      padding-right: 50%;
      padding-top: 61px;
      padding-bottom: 75px;
    }

    &__textual {
      margin-bottom: 14px;
    }

    &__title {
      font-size: 38px;
      text-align: left;
      margin-bottom: 13px;
    }

    &__text {
      max-width: 262px;

      text-align: left;
    }

    &__image {
      position: absolute;
      top: 95px;
      left: calc(50% + 30px);
      right: calc((100vw - 720px) / -2 - 20px);
      bottom: 91px;

      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

@media (min-width: 992px) {
  .calculation {
    &__image {
      right: calc((100vw - 960px) / -2 - 20px);
    }
  }
}

@media (min-width: 1200px) {
  .calculation {
    &__content {
      padding-top: 87px;
      padding-bottom: 107px;
    }

    &__textual {
      margin-bottom: 29px;
    }

    &__title {
      display: block;
      margin-bottom: 16px;
    }

    &__text {
      max-width: none;
    }

    &__image {
      top: 87px;
      right: 0;
      bottom: 85px;
    }
  }
}
