.module {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 670px;
  max-width: 100%;
  padding-top: 100px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 65px;
  overflow: hidden;

  background-color: #fff;
}

.bg {
  position: absolute;
  top: -50px;
  left: -50px;

  width: 217px;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

// .icon {
//   position: relative;

//   width: 60px;
//   height: 60px;
//   margin-bottom: 27px;

//   border-radius: 50%;
//   background-color: #3a99a3;

//   &::before {
//     position: absolute;
//     top: 20px;
//     left: 17px;

//     width: 27px;
//     height: 15px;

//     border-left: 3px solid #fff;
//     border-bottom: 3px solid #fff;
//     transform: rotate(-45deg);

//     content: '';
//   }
// }

.title {
  max-width: 245px;
  margin-bottom: 22px;

  font-size: 23px;
  font-weight: 800;
  line-height: 1.36;
  text-align: center;
  text-transform: uppercase;

  color: #3a99a3;
}

.subtitle {
  max-width: 320px;
  margin-bottom: 0;

  line-height: 1.45;
  text-align: center;
}
