.module {
  position: relative;
  width: 42px;
  height: 42px;

  border: none;
  background-color: transparent;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 26px;
    height: 1px;

    background-color: #000;
    transform: translate(-50%, -50%) rotate(45deg);

    transition: 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 26px;
    height: 1px;

    background-color: #000;
    transform: translate(-50%, -50%) rotate(-45deg);

    transition: 0.2s;

    content: '';
  }

  &:active {
    &::before {
      background-color: #bbb;
    }

    &::after {
      background-color: #bbb;
    }
  }
}

@media (hover: hover) {
  .module {
    &:hover {
      &::before {
        background-color: #bbb;
      }

      &::after {
        background-color: #bbb;
      }
    }
  }
}
