.module {
  width: 100%;
  height: 60px;
  padding: 0 20px;

  border-radius: 3px;
  background-color: #fff;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}
