.module {
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 220px;
}

.adults {
  margin-bottom: 20px;
}

.babies {
  margin-bottom: 20px;
}
