.module {
  position: relative;

  width: 970px;
  max-width: 100%;
  padding-top: 59px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 58px;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.title {
  margin-bottom: 32px;

  font-size: 20px;
  font-weight: 800;
  line-height: 1.3069;
  text-align: center;
  text-transform: uppercase;

  color: #3a99a3;
}

.content {
  p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }

  .title {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
