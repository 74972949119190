.children {
  background-color: #ededed;

  &__top {
    padding-top: 20px;
    font-size: 18px;
    line-height: 1.25;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 51px;
    padding-top: 43px;
    padding-bottom: 52px;
  }

  &__item {
    position: relative;

    padding-top: 21px;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.41;
    text-align: center;

    strong {
      color: map-get($colors, 'main');
    }

    span {
      color: map-get($colors, 'second');
    }

    &::before {
      position: absolute;
      top: 0;
      left: 50%;

      width: 30px;
      height: 3px;

      background-color: map-get($colors, 'main');
      transform: translateX(-50%);

      content: '';
    }
  }
}

@media (min-width: 768px) {
  .children {
    &__list {
      align-items: center;
      row-gap: 65px;
      padding-top: 79px;
      padding-bottom: 69px;
    }

    &__item {
      max-width: 356px;

      padding-top: 15px;
    }
  }
}

@media (min-width: 1200px) {
  .children {
    &__list {
      flex-direction: row;
      column-gap: 50px;
      padding-bottom: 84px;
    }
  }
}
