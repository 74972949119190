.olympia {
  &__content {
    display: flex;
    flex-direction: column;
    // padding-top: 80px;
    padding-bottom: 61px;
  }
  &__logo {
    align-self: center;
    width: 100px;
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 22px;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;
  }
  &__title strong {
    color: #3a99a3;
  }
  &__title span {
    color: #143437;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  &__image {
    position: relative;
    padding-bottom: 56%;
  }
  &__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .olympia {
    &__content {
      // padding-top: 61px;
      padding-bottom: 77px;
    }
    &__title {
      margin-bottom: 31px;
      font-size: 38px;
    }
  }
}

@media (min-width: 1200px) {
  .olympia {
    &__content {
      // padding-top: 112px;
      padding-bottom: 127px;
    }
    &__list {
      display: flex;
      flex-direction: row;
    }
    &__item {
      width: calc(50% - 15px);
    }
    &__image {
      padding-bottom: 62%;
    }
    &__title {
      margin-bottom: 51px;
    }
  }
}
