.header {
  $p: &;

  &__content {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 103px;
  }

  &__logo {
    width: 116px;
    margin-top: 11px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    align-items: flex-end;
    width: 42px;
    height: 42px;
    margin-top: 22px;
    margin-right: -5px;
    padding-right: 5px;

    &::before {
      width: 32px;
      height: 2px;
      margin-bottom: 7px;

      background-color: #000;

      content: '';
    }

    span {
      width: 22px;
      height: 2px;
      margin-bottom: 7px;

      background-color: #000;
    }

    &::after {
      width: 32px;
      height: 2px;

      background-color: #000;

      content: '';
    }
  }

  &__block {
    display: none;
  }

  &__nav {
    a {
      transition: 0.2s;
    }
  }

  &__phone {
    transition: 0.2s;
  }

  &__callback {
    transition: 0.2s;
  }

  &--open {
    #{$p}__block {
      position: absolute;
      z-index: 1;
      top: 0;
      right: -20px;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 242px;
      padding-top: 88px;
      padding-right: 20px;
      padding-bottom: 60px;

      background-color: #fff;
    }

    #{$p}__button {
      position: relative;
      z-index: 1;

      align-items: center;
      padding-right: 0;

      &::before {
        width: 26px;
        height: 1px;
        margin: 0;

        background-color: #000;
        transform: rotate(45deg);
      }

      span {
        display: none;
      }

      &::after {
        position: relative;
        top: -1px;

        width: 26px;
        height: 1px;

        background-color: #000;
        transform: rotate(-45deg);
      }
    }

    #{$p}__nav {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 44px;

      a {
        font-weight: 500;
        line-height: 2.44;

        &:not(:last-child) {
          margin-bottom: 13px;
        }

        &:active {
          color: map-get($colors, 'main');
        }
      }
    }

    #{$p}__info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    #{$p}__phones {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 12px;
    }

    #{$p}__phone {
      display: flex;
      align-items: center;

      color: map-get($colors, 'second');

      img {
        width: 16px;
        margin-right: 5px;
      }

      span {
        font-weight: 500;
        line-height: 2.44;
      }

      &:active {
        color: map-get($colors, 'main');
      }
    }

    #{$p}__callback {
      height: 40px;
      padding-left: 16px;
      padding-right: 16px;

      font-size: 16px;

      border: 1px solid map-get($colors, 'main');
      border-radius: 3px;
      color: map-get($colors, 'main');

      &:active {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }
  }
}

@media (min-width: 768px) {
  .header {
    &__content {
      height: 91px;
    }

    &__block {
      display: flex;
      align-self: center;
    }

    &__nav {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: calc((100vw - 720px) / -2 - 20px);
      right: calc((100vw - 720px) / -2 - 20px);

      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 49px;
      height: 56px;

      color: #fff;
      background-color: map-get($colors, 'second');

      a {
        font-weight: 500;
        line-height: 2.44;

        &:active {
          color: map-get($colors, 'main-light');
        }
      }
    }

    &__info {
      display: flex;
    }

    &__phones {
      display: flex;
    }

    &__phone {
      display: flex;
      align-items: center;
      margin-right: 27px;

      color: map-get($colors, 'second');

      img {
        width: 16px;
        margin-right: 5px;
      }

      span {
        font-weight: 500;
        line-height: 2.44;
      }

      &:active {
        color: map-get($colors, 'main');
      }
    }

    &__callback {
      height: 40px;
      padding-left: 16px;
      padding-right: 16px;

      font-size: 16px;

      border: 1px solid map-get($colors, 'main');
      border-radius: 3px;
      color: map-get($colors, 'main');

      &:active {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }

    &__button {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  .header {
    &__nav {
      left: calc((100vw - 960px) / -2 - 20px);
      right: calc((100vw - 960px) / -2 - 20px);
    }
  }
}

@media (min-width: 1200px) {
  .header {
    &__nav {
      left: calc((100vw - 1140px) / -2 - 20px);
      right: calc((100vw - 1140px) / -2 - 20px);

      column-gap: 77px;
    }
  }
}

@media (hover: hover) {
  .header {
    &__nav {
      a {
        &:hover {
          color: map-get($colors, 'main-light');
        }
      }
    }

    &__phone {
      &:hover {
        color: map-get($colors, 'main');
      }
    }

    &__callback {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }
  }
}
