// @each $name, $value in $breakpoints {
//   @include media-query($value) {
//     .container {
//       width: 100%;
//       margin-left: auto;
//       margin-right: auto;
//       padding-left: map-get($gutter-values, $name) * 0.5px;
//       padding-right: map-get($gutter-values, $name) * 0.5px;
//       @if ($value == 0) {
//         &--fluid {
//           width: 100%;
//           padding-left: map-get($gutter-values, $name) * 0.5px;
//           padding-right: map-get($gutter-values, $name) * 0.5px;
//         }
//       } @else {
//         max-width: map-get($container-values, $name) * 1px;
//       }
//     }
//     .row {
//       display: flex;
//       flex-wrap: wrap;
//       margin-left: map-get($gutter-values, $name) * -0.5px;
//       margin-right: map-get($gutter-values, $name) * -0.5px;
//     }
//     @for $i from 1 through $columns {
//       @if ($value == 0) {
//         .col-#{$i} {
//           flex: 0 0 (100 / ($columns / $i)) * 1%;
//           position: relative;
//           width: 100%;
//           max-width: (100 / ($columns / $i)) * 1%;
//           padding-left: map-get($gutter-values, $name) * 0.5px;
//           padding-right: map-get($gutter-values, $name) * 0.5px;
//         }
//         .offset-#{$i} {
//           margin-left: (100 / ($columns / $i)) * 1%;
//         }
//       } @else {
//         .col-#{$name}-#{$i} {
//           flex: 0 0 (100 / ($columns / $i)) * 1%;
//           position: relative;
//           width: 100%;
//           max-width: (100 / ($columns / $i)) * 1%;
//           padding-left: map-get($gutter-values, $name) * 0.5px;
//           padding-right: map-get($gutter-values, $name) * 0.5px;
//         }
//         .offset-#{$name}-#{$i} {
//           margin-left: (100 / ($columns / $i)) * 1%;
//         }
//       }
//     }
//   }
// }

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    // padding-left: 0;
    // padding-right: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

// @media (min-width: 1400px) {
//   .container {
//     max-width: 1320px;
//   }
// }
