.map {
  height: 320px;
}

@media (min-width: 768px) {
  .map {
    height: 460px;
  }
}

@media (min-width: 1200px) {
  .map {
  }
}
