.about {
  position: relative;

  &__content {
    padding-top: 49px;
  }

  &__bg {
    display: none;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;

    font-size: 25px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;

    strong {
      color: map-get($colors, 'main');
    }

    span {
      color: map-get($colors, 'second');
    }
  }

  &__text {
    margin-bottom: 36px;

    line-height: 1.82;
    text-align: center;

    p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      span {
        color: map-get($colors, 'main');
      }
    }
  }

  &__description {
    margin-left: -20px;
    margin-right: -20px;
    padding: 35px 20px;

    font-weight: 500;
    line-height: 2.09;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'main');
  }

  &__slider {
    position: relative;

    margin-left: -20px;
    margin-right: -20px;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 93%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 20px;

    width: 42px;
    height: 42px;

    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 33px;
      height: 33px;

      border: 1px solid map-get($colors, 'main');
      background-color: rgba(255, 255, 255, 0.57);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 19px;

      width: 8px;
      height: 8px;

      border-left: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 20px;

    width: 42px;
    height: 42px;

    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 33px;
      height: 33px;

      border: 1px solid map-get($colors, 'main');
      background-color: rgba(255, 255, 255, 0.57);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 19px;

      width: 8px;
      height: 8px;

      border-right: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(-45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      transform: translateY(-50%);

      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }
}

@media (min-width: 768px) {
  .about {
    &__content {
      position: relative;

      padding-top: 64px;
      padding-bottom: 100px;
    }

    &__bg {
      position: absolute;
      top: 600px;
      left: -100px;

      display: block;
      width: 196px;
    }

    &__first {
      margin-bottom: 80px;
    }

    &__title {
      max-width: 460px;
      margin-bottom: 17px;

      font-size: 38px;
      text-align: left;
    }

    &__text {
      line-height: 2.09;
      text-align: left;
    }

    &__description {
      position: relative;

      margin: 0;
      padding-left: 38px;
      padding-right: 38px;
      padding-bottom: 30px;
    }

    &__slider {
      position: relative;

      margin: 0;
    }

    &__slide {
      padding-bottom: 65%;
    }

    &__prev {
      left: 0;

      width: 46px;
      height: 46px;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__next {
      right: 0;

      width: 46px;
      height: 46px;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .about {
    &__content {
      display: flex;
      padding-top: 131px;
      padding-bottom: 149px;
    }

    &__bg {
      top: auto;
      left: -200px;
      bottom: 50px;

      width: 280px;
    }

    &__first {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-right: 30px;
    }

    &__second {
      width: 500px;
    }

    &__slide {
      padding-bottom: 664px;
    }

    &__nav {
      position: absolute;
      right: 0;
      bottom: 0;

      display: flex;
    }

    &__prev {
      position: relative;
      top: 0;
      left: 0;

      width: 46px;
      height: 46px;

      transform: none;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__next {
      position: relative;
      top: 0;
      right: 0;

      width: 46px;
      height: 46px;

      transform: none;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__text {
      margin-bottom: auto;
    }

    &__description {
      position: relative;
      z-index: 2;

      margin-right: -86px;
    }
  }
}

@media (hover: hover) {
  .about {
    &__prev {
      &:active {
        transform: none;
      }

      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:active {
        transform: none;
      }

      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }
  }
}
