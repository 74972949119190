.module {
  display: flex;
  align-items: flex-start;

  &.isInverted {
    .text {
      span {
        color: rgba(255, 255, 255, 0.7);
      }

      u {
        color: rgba(255, 255, 255, 0.7);

        &:active {
          color: #143437;
        }
      }
    }
  }
}

.checkbox {
  margin-top: 4px;
  margin-right: 16px;

  flex-shrink: 0;
}

.text {
  margin-bottom: 0;

  line-height: 1.34;

  span {
    color: #143437;
  }

  u {
    text-decoration: underline;

    color: #3a99a3;
    cursor: pointer;

    transition: 0.2s;

    &:active {
      color: lighten(#3a99a3, 10%);
    }
  }
}

@media (hover: hover) {
  .text {
    u {
      &:hover {
        color: lighten(#3a99a3, 10%);
      }
    }
  }

  .module {
    &.isInverted {
      .text {
        u {
          &:hover {
            color: #143437;
          }
        }
      }
    }
  }
}
