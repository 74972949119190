.reviews {
  &__content {
    padding-top: 68px;
  }

  &__title {
    margin-bottom: 22px;

    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'second');
  }

  &__slider {
    position: relative;

    margin-bottom: 45px;
  }

  &__slide {
    position: relative;

    border: 1px solid #f0f0f0;
    border-radius: 3px;

    padding-top: 62px;
    padding-left: 35px;
    padding-right: 26px;
    padding-bottom: 36px;

    &::before {
      position: absolute;
      top: 8px;
      left: 20px;

      font-size: 86px;

      color: #e1e1e1;

      content: '“';
    }

    &--cut {
      .reviews__text {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .reviews__button {
        visibility: visible;
      }
    }
  }

  &__text {
    min-height: 152px;

    font-size: 14px;
    line-height: 1.81;

    color: map-get($colors, 'second');
  }

  &__button {
    margin-bottom: 28px;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.18;

    color: map-get($colors, 'main');
    visibility: hidden;

    transition: 0.2s;

    &:active {
      color: map-get($colors, 'main-light');
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.74;
    text-transform: uppercase;
  }

  &__grade {
    font-weight: 500;
    line-height: 1.18;

    color: map-get($colors, 'main');
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -20px;

    width: 42px;
    height: 42px;

    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 33px;
      height: 33px;

      border: 1px solid map-get($colors, 'main');
      background-color: rgba(255, 255, 255, 0.57);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 19px;

      width: 8px;
      height: 8px;

      border-left: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -20px;

    width: 42px;
    height: 42px;

    transform: translateY(-50%);

    transition: 0.2s;

    // &.swiper-button-disabled {
    //   display: none;
    // }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 33px;
      height: 33px;

      border: 1px solid map-get($colors, 'main');
      background-color: rgba(255, 255, 255, 0.57);
      transform: translate(-50%, -50%);

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 19px;

      width: 8px;
      height: 8px;

      border-right: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(-45deg);

      transition: 0.2s;

      content: '';
    }

    &:active {
      &::before {
        background-color: map-get($colors, 'main');
      }

      &::after {
        border-color: #fff;
      }
    }
  }

  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 226px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;

    font-weight: 500;
    text-transform: uppercase;

    border: 1px solid map-get($colors, 'main');
    border-radius: 3px;
    color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    &__content {
      padding-top: 75px;
    }

    &__title {
      margin-bottom: 37px;

      font-size: 38px;
      text-align: left;
    }

    &__slider {
      margin-bottom: 37px;
    }

    &__slide {
      padding-top: 73px;
      padding-left: 46px;

      &::before {
        top: 22px;
        left: 40px;
      }
    }

    &__text {
      margin-bottom: 5px;
    }

    &__button {
      margin-bottom: 42px;
    }

    &__nav {
      position: absolute;
      right: 0;
      bottom: calc(100% + 37px);

      display: flex;
    }

    &__prev {
      position: relative;
      top: 0;
      left: 0;

      width: 46px;
      height: 46px;

      transform: none;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__next {
      position: relative;
      top: 0;
      right: 0;

      width: 46px;
      height: 46px;

      transform: none;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    &__content {
      padding-top: 123px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__slider {
      margin-bottom: 42px;
    }
  }
}

@media (hover: hover) {
  .reviews {
    &__button {
      &:hover {
        color: map-get($colors, 'main-light');
      }
    }

    &__prev {
      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__add {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }
  }
}
