.nutrition {
  &__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 61px;
  }

  &__first {
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 22px;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;
  }

  &__title strong {
    color: #3a99a3;
  }

  &__title span {
    color: #143437;
  }

  &__schedule {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    text-align: center;
    color: #143437;
  }

  &__schedule strong {
    margin-bottom: 3px;
    font-weight: 600;
  }

  &__text {
    margin-bottom: 24px;
    padding: 16px;
    line-height: 1.82;
    text-align: center;
    color: #143437;
    background-color: #ededed;
  }

  &__text p:not(:last-child) {
    margin-bottom: 16px;
  }

  &__assortment {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 7px;
    column-gap: 20px;
  }

  &__assortment span {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.74;
    color: #143437;
  }

  &__assortment span::before {
    width: 5px;
    height: 5px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #3a99a3;
    content: '';
  }

  &__slider {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
  }

  &__slide {
    display: flex;
    padding-bottom: 93%;
  }

  &__slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__prev {
    position: absolute;
    top: 50%;
    transition: 0.2s;
    z-index: 1;
    left: 20px;
    width: 42px;
    height: 42px;
    background-color: transparent;
    transform: translateY(-50%);
  }

  &__prev::before {
    position: absolute;
    top: 50%;
    transition: 0.2s;
    left: 50%;
    width: 33px;
    height: 33px;
    border: 1px solid #3a99a3;
    background-color: hsla(0, 0%, 100%, 0.57);
    transform: translate(-50%, -50%);
    content: '';
  }

  &__prev::after {
    position: absolute;
    top: 17px;
    left: 19px;
    width: 8px;
    height: 8px;
    border-left: 1px solid #143437;
    border-bottom: 1px solid #143437;
    transform: rotate(45deg);
    transition: 0.2s;
    content: '';
  }

  &__next {
    position: absolute;
    top: 50%;
    transition: 0.2s;
    z-index: 1;
    right: 20px;
    width: 42px;
    height: 42px;
    transform: translateY(-50%);
  }

  &__next::before {
    position: absolute;
    top: 50%;
    transition: 0.2s;
    left: 50%;
    width: 33px;
    height: 33px;
    border: 1px solid #3a99a3;
    background-color: hsla(0, 0%, 100%, 0.57);
    transform: translate(-50%, -50%);
    content: '';
  }

  &__next::after {
    position: absolute;
    top: 17px;
    right: 19px;
    width: 8px;
    height: 8px;
    border-right: 1px solid #143437;
    border-bottom: 1px solid #143437;
    transform: rotate(-45deg);
    transition: 0.2s;
    content: '';
  }
}

@media (min-width: 768px) {
  .nutrition {
    &__content {
      padding-bottom: 100px;
    }

    &__first {
      justify-content: center;
    }

    &__title {
      font-size: 38px;
    }

    &__slider {
      margin: 0;
    }

    &__slide {
      padding-bottom: 65%;
    }

    &__prev {
      left: 0;

      width: 46px;
      height: 46px;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__next {
      right: 0;

      width: 46px;
      height: 46px;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .nutrition {
    &__content {
      flex-direction: row;
      padding-bottom: 149px;
    }

    &__first {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-right: 30px;
    }

    &__title {
      max-width: 400px;
      align-self: center;
    }

    &__second {
      flex-shrink: 0;
      width: 500px;
    }

    &__slide {
      padding-bottom: 664px;
    }

    &__prev {
      width: 46px;
      height: 46px;

      transform: none;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }

    &__next {
      width: 46px;
      height: 46px;

      transform: none;

      &::before {
        width: 46px;
        height: 46px;
      }

      &::after {
        width: 11px;
        height: 11px;
      }
    }
  }
}

@media (hover: hover) {
  .nutrition {
    &__prev {
      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }

    &__next {
      &:hover {
        &::before {
          background-color: map-get($colors, 'main');
        }

        &::after {
          border-color: #fff;
        }
      }
    }
  }
}
