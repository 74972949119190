.vacancy {
  &__content {
    padding-top: 10px;
  }

  &__title {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 20px;
    text-align: center;

    margin-bottom: 50px;
  }

  &__categories {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }

  &__caption {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.36;

    text-transform: uppercase;
  }

  &__desc {
    margin-bottom: 16px;

    font-size: 16px;
    line-height: 1.36;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  &__item {
    background-color: #f2f2f2;
  }

  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    font-size: 18px;
    font-weight: 700;
    line-height: 1.36;

    color: #fff;
    background-color: map-get($colors, 'main');
    cursor: pointer;

    &::after {
      display: inline-flex;
      flex-shrink: 0;
      width: 10px;
      height: 10px;
      margin-top: -3px;
      margin-left: 10px;

      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);

      content: '';
    }
  }

  &__item_active &__name {
    &::after {
      margin-top: 3px;

      border-left: none;
      border-bottom: none;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }
  }

  &__text {
    display: none;
    padding: 16px;

    p {
      margin-bottom: 16px;
    }

    strong {
      font-weight: 700;
    }

    a {
      font-weight: 700;

      color: map-get($colors, 'main');
    }
  }

  &__item_active &__text {
    display: block;
  }
}

@media (min-width: 768px) {
  .vacancy {
    &__content {
      padding-top: 100px;
    }

    &__title {
      font-size: 38px;
    }
  }
}

@media (min-width: 1200px) {
  .vacancy {
    &__subtitle {
      margin-bottom: 60px;
    }
  }
}
