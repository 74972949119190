.module {
  position: relative;

  width: 670px;
  max-width: 100%;
  padding-top: 59px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 58px;
  overflow: hidden;

  background-color: #f9f9f9;
}

.bg {
  position: absolute;
  top: -50px;
  left: -50px;

  width: 217px;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 37px;

  font-size: 23px;
  font-weight: 800;
  line-height: 1.3069;
  text-align: center;
  text-transform: uppercase;

  color: #3a99a3;
}

.grade {
  margin-bottom: 80px;
}

.gradeTitle {
  margin-bottom: 12px;

  font-size: 14px;
  line-height: 1.74;

  color: rgba(0, 0, 0, 0.4);
}

.slider {
  height: 8px;
  padding: 0 13px;

  border-radius: 10px;
  background-image: linear-gradient(90deg, #3a99a3 50%, #ededed 50%);

  :global {
    .rc-slider {
      height: 8px;
      padding: 0;
    }

    .rc-slider-rail {
      height: 8px;

      border-radius: 0;
      background-color: #ededed;
    }

    .rc-slider-track {
      height: 8px;

      border-radius: 0;
      background-color: #3a99a3;
    }

    .rc-slider-step {
      display: none;
    }

    .rc-slider-mark {
      top: 23px;
      // left: -10px;
      // right: -10px;
      // width: auto;
    }

    .rc-slider-mark-text {
      font-size: 16px;

      color: #959595;
    }

    .rc-slider-handle {
      width: 26px;
      height: 26px;
      margin-top: -10px;

      // border: 1px solid #d6d6d6;
      border-color: #3a99a3;
      background-color: #3a99a3;

      &:active {
        box-shadow: none;
      }

      // &::before {
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;

      //   width: 7px;
      //   height: 7px;

      //   border-radius: 50%;
      //   background-color: #d6d6d6;
      //   transform: translate(-50%, -50%);

      //   content: '';
      // }
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      box-shadow: none;
    }
  }
}

.name {
  margin-bottom: 20px;
}

.email {
  margin-bottom: 20px;
}

.city {
  margin-bottom: 20px;
}

.text {
  margin-bottom: 20px;
}

.policy {
  margin-bottom: 37px;
}

.button {
  align-self: center;
  width: 280px;
  height: 60px;

  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  border-radius: 3px;
  color: #fff;
  background-color: #3a99a3;

  transition: 0.2s;

  &:active {
    background-color: lighten(#3a99a3, 10%);
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 30px;
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      background-color: lighten(#3a99a3, 10%);
    }
  }
}
