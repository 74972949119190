.module {
  position: fixed;
  z-index: 100;
  left: 20px;
  right: 20px;
  bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 40px 18px 20px;

  background-color: #3a99a3;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;

  &::before {
    background-color: #fff;
  }

  &::after {
    background-color: #fff;
  }
}

.text {
  margin-bottom: 22px;

  font-size: 12px;
  line-height: 1.5;

  color: #fff;
}

.detail {
  text-decoration: underline;
  padding: 0;

  border: none;
  color: #fff;
  background-color: transparent;

  transition: 0.2s;

  &:active {
    color: #143437;
  }
}

.confirm {
  flex-shrink: 0;
  height: 30px;
  padding: 0 12px;

  font-size: 12px;

  border: none;
  color: #3a99a3;
  background-color: #fff;

  transition: 0.2s;

  &:active {
    color: #fff;
    background-color: #143437;
  }
}

@media (min-width: 768px) {
  .module {
    left: 50%;
    right: auto;

    width: 720px;

    transform: translateX(-50%);
  }
}

@media (min-width: 1200px) {
  .module {
    flex-direction: row;
    align-items: center;
    width: 770px;
    padding-left: 54px;
    padding-right: 20px;
  }

  .close {
    top: 0;
    left: 0;
    right: auto;
  }

  .text {
    margin-bottom: 0;
  }
}

@media (hover: hover) {
  .detail {
    &:hover {
      color: #143437;
    }
  }

  .confirm {
    &:hover {
      color: #fff;
      background-color: #143437;
    }
  }
}
