.module {
  width: 21px;
  height: 21px;

  border: 1px solid #9ca6c7;
  border-radius: 50%;
  cursor: pointer;

  appearance: none;

  &:checked {
    border-color: #001e89;

    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Ccircle cx="10" cy="10" r="5" fill="%23001E89" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
