.module {
  position: relative;

  width: 670px;
  max-width: 100%;
  padding-top: 127px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 58px;
  overflow: hidden;

  background-color: #f9f9f9;
}

.bg {
  position: absolute;
  right: -50px;
  bottom: -70px;

  width: 217px;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.text {
  position: relative;

  margin-bottom: 14px;

  font-size: 14px;
  line-height: 1.81;

  color: #143437;

  &::before {
    position: absolute;
    top: -80px;
    left: 0;

    font-size: 86px;

    color: #e1e1e1;

    content: '“';
  }
}

.name {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.74;
  text-transform: uppercase;

  color: #143437;
}

.grade {
  font-weight: 500;
  line-height: 1.18;

  color: #3a99a3;
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
