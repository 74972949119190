.screen {
  background-color: map-get($colors, 'bg');

  &__content {
    padding-bottom: 79px;
  }

  &__first {
    position: relative;

    margin-bottom: 45px;
  }

  &__bg {
    position: relative;

    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 89%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: rgba(249, 249, 249, 0.77);
      backdrop-filter: blur(7px);

      content: '';
    }
  }

  &__image {
    position: absolute;
    top: 25px;
    left: 0px;
    right: 0px;
    bottom: 25px;

    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      height: 46px;

      border-radius: 50%;
      background-color: #3a99a3;
      transform: translate(-50%, -50%);
      backdrop-filter: blur(9px);

      &::before {
        position: relative;
        left: 3px;
        top: 1px;

        height: 0;
        width: 0;

        border-bottom: 10px solid transparent;
        border-left: 17px solid #fff;
        border-top: 10px solid transparent;

        content: '';
      }
    }
  }

  &__second {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    display: flex;
    flex-direction: column;

    span {
      font-size: 27px;
      font-weight: 500;
      line-height: 1.4;
      text-transform: uppercase;

      color: map-get($colors, 'second');
    }

    strong {
      font-size: 42px;
      font-weight: 700;
      line-height: 1.4;
      text-transform: uppercase;

      color: map-get($colors, 'main');
    }

    margin-bottom: 11px;
  }

  &__subtitle {
    align-self: center;
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
  }

  &__offer {
    max-width: 280px;
    margin-bottom: 37px;
    padding: 7px 21px;

    font-size: 19px;
    font-weight: 500;
    line-height: 1.74;
    text-align: center;
    text-transform: uppercase;

    border-radius: 3px;
    color: map-get($colors, 'second');
    background-color: rgba(105, 211, 203, 0.19);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__booking {
    width: 280px;
    height: 60px;
    margin-bottom: 15px;

    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    border-radius: 3px;
    color: #fff;
    background-color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      background-color: map-get($colors, 'main-light');
    }
  }

  &__calculation {
    width: 280px;
    height: 60px;

    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    border: 1px solid map-get($colors, 'main');
    border-radius: 3px;
    color: map-get($colors, 'main');

    transition: 0.2s;

    &:active {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 576px) {
  .screen {
    &__bg {
      margin-left: calc((100vw - 540px) / -2 - 20px);
      margin-right: calc((100vw - 540px) / -2 - 20px);
    }
  }
}

@media (min-width: 768px) {
  .screen {
    &__content {
      display: flex;
      padding-top: 56px;
      padding-bottom: 0;
    }

    &__first {
      flex: 0 0 50%;
      margin-bottom: 0;
    }

    &__bg {
      height: 470px;
      margin-left: calc((100vw - 720px) / -2 - 20px);
      margin-right: 93px;
      padding-bottom: 0;
    }

    &__image {
      top: 43px;
      left: calc((100vw - 720px) / -2 - 20px);
      right: 42px;
      bottom: 43px;
    }

    &__second {
      flex: 0 0 50%;
      padding-top: 59px;
    }

    &__title {
      margin-bottom: 0;

      span {
        font-size: 32px;
        text-align: center;
      }

      strong {
        margin-top: -10px;

        font-size: 52px;
        text-align: center;
      }
    }

    &__offer {
      width: 100%;
      max-width: none;
      margin-bottom: 51px;
      padding: 5px 0;

      font-size: 15px;
    }

    &__booking {
      width: 320px;
      margin-bottom: 20px;
    }

    &__calculation {
      width: 320px;
    }
  }
}

@media (min-width: 992px) {
  .screen {
    &__bg {
      margin-left: calc((100vw - 960px) / -2 - 20px);
    }

    &__image {
      left: calc((100vw - 960px) / -2 - 20px);
    }
  }
}

@media (min-width: 1200px) {
  .screen {
    &__bg {
      height: 601px;
      margin-left: calc((100vw - 1140px) / -2 - 20px);
      margin-right: 134px;
    }

    &__image {
      top: 55px;
      left: calc((100vw - 1140px) / -2 - 20px);
      right: 69px;
      bottom: 55px;

      span {
        width: 62px;
        height: 62px;
      }
    }

    &__second {
      align-items: flex-start;
      padding-top: 127px;
    }

    &__title {
      span {
        font-size: 49px;
      }

      strong {
        margin-top: -30px;

        font-size: 76px;
      }
    }

    &__offer {
      width: auto;
      margin-bottom: 82px;
      padding: 6px 19px;

      font-size: 19px;
    }

    &__buttons {
      flex-direction: row;
    }

    &__booking {
      width: 209px;
      margin-right: 19px;
      margin-bottom: 0;
    }

    &__calculation {
      width: 277px;
    }
  }
}

@media (hover: hover) {
  .screen {
    &__booking {
      &:hover {
        background-color: map-get($colors, 'main-light');
      }
    }

    &__calculation {
      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }
  }
}
