.advantages {
  background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 75px;
    padding-bottom: 62px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    font-size: 26px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    text-transform: uppercase;

    strong {
      color: map-get($colors, 'main');
    }

    span {
      color: map-get($colors, 'second');
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 53px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    object-fit: contain;
  }

  &__name {
    margin-bottom: 12px;

    font-weight: 700;
    line-height: 1.39;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'second');
  }

  &__text {
    line-height: 1.48;
    text-align: center;

    color: map-get($colors, 'second');
  }
}

@media (min-width: 768px) {
  .advantages {
    &__content {
      padding-top: 62px;
      padding-bottom: 64px;
    }

    &__title {
      margin-bottom: 61px;

      font-size: 38px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    &__item {
      align-items: flex-start;
      width: calc(50% - 30px);
      margin: 0 15px;
    }

    &__name {
      margin-bottom: 9px;

      text-align: left;
    }

    &__text {
      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  .advantages {
    &__content {
      padding-top: 123px;
      padding-bottom: 120px;
    }

    &__title {
      display: block;
      margin-bottom: 79px;

      text-align: left;
    }

    &__list {
      row-gap: 65px;
    }

    &__item {
      width: calc(33.33333% - 30px);
    }
  }
}
